<template>
  <span>
    <span @click.stop="open">
      <slot></slot>
    </span>
    <el-dialog append-to-body :title="title" top="15vh" :visible="dialogVisible" @close="close">
      <div class="dialog-wrap" style="background:#ddd">
        <div class="my-flex my-row-center">
          <el-image fit="contain" style="width:400px;height:400px;" :src="url"></el-image>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm">上传</el-button>
      </div>
    </el-dialog>
  </span>
</template> 
      
   <script>
// import elDragDialog from '@/directive/el-drag-dialog'

export default {
  name: '',
  directives: {},
  components: {},
  props: {
    title: {
      type: String,
      default: '预览'
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  computed: {},
  created() {},

  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    confirm() {
      this.dialogVisible = false
      this.$emit('confirm')
    },
    open() {
      this.dialogVisible = true
    }
  }
}
</script>
      
<style lang="scss" scoped>
</style>
      