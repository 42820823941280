<template>
  <div>
    <ul>
      <draggable
        :list="fileList"
        class="box-card"
        handle=".mover"
        :class="[type==='card' ? 'box-card' : 'box-list']"
        @end="()=>{$emit('change', fileList)}"
      >
        <li
          :class="[type==='card' ? 'box-card-li' : 'box-list-li','mover']"
          :style="{'width':type==='card' ? width : '100%','height': type==='card' ? height : '100%'}"
          v-for="(item,index) in fileList"
          :key="index"
          @mouseover="overIndex = index"
          @mouseleave="overIndex = '-1'"
        >
          <!-- ======================================================图片展示====================================================== -->
          <template v-if="type === 'card'">
            <el-image
              fit="cover"
              :style="{'width':width,'height':height}"
              ref="myImage"
              :src="item.url"
              :preview-src-list="previewFileList"
            ></el-image>
            <span v-if="overIndex == index ">
              <i class="el-icon-view" style="margin-right: 5px;" @click="handlePreview(index)"></i>
              <i class="el-icon-delete" v-if="showDelete" @click="handleDelete(index)"></i>
            </span>
          </template>
          <!-- ======================================================文本展示====================================================== -->
          <template v-else>
            <a
              :href="item.url"
              target="_blank"
              style="display: block;overflow: hidden;text-overflow: ellipsis; -webkit-box-orient: vertical;-webkit-line-clamp: 1;"
              :style="{
                maxWidth : textWidth
              }"
            >
              <i class="el-icon-tickets"></i>
              {{item.originalName}}
            </a>
            <i class="el-icon-close close" v-if="overIndex == index && showDelete" @click="handleDelete(index)"></i>
          </template>
        </li>
        <!-- ======================================================上传按钮====================================================== -->
        <li v-if="fileList.length < Number(limit) && arrowUpload" style="display: flex;">
          <!-- 选文件上传 -->
          <el-upload
            action
            list-type="text"
            :auto-upload="false"
            :show-file-list="false"
            :http-request="handleUpload"
            :on-change="changeUpload"
            :on-exceed="onExceed"
            :limit="Number(100)"
            :before-upload="beforeUpload"
            :disabled="disabled"
          >
            <div class="box-plus my-flex" :style="{'width':width,'height':height}" v-if="type === 'card'" slot="trigger" @click="doNothing">
              <i class="el-icon-plus" style="font-size:16px;margin-bottom: 5px;"></i>
              <span>上传图片</span>
            </div>
            <el-button size="mini" v-else>{{buttonText}}</el-button>
          </el-upload>

          <!-- 粘贴上传 -->
          <div
            v-if="type === 'card' && showPasteUpload"
            id="copyDiv"
            isPasteDivFocus
            tabindex="-1"
            class="my-m-l-10"
            :style="{'width':width,'height':height}"
            @paste="handlePaste"
          >
            <div v-if="!isPasteDivFocus" class="box-plus" style="width: 100%;height: 100%;">
              <i class="el-icon-document-copy" style="font-size:16px;margin-bottom: 5px;"></i>
              <span>粘贴上传</span>
            </div>
            <div
              v-else
              class="box-plus"
              style="width: 100%;height: 100%;border:1px dashed #409EFF;background:#ECF5FF;color:#999;font-size:12px;text-align: center;"
            >
              <div>复制后</div>
              <div>在此处</div>
              <div>Ctrl+V</div>
            </div>
          </div>
          <!-- 拍照上传 -->
          <div v-if="type === 'card' && showCameraUpload" class="box-plus my-m-l-10 my-flex" :style="{'width':width,'height':height}">
            <el-badge value="新" :hidden="new Date()>=new Date('2024-06-30')" style="width: 100%; height: 100%">
              <camera-dialog :limit="limit - fileList.length" @confirm="cameraConfirm" style="width: 100%; height:100%;">
                <i class="el-icon-camera" style="font-size: 16px;margin-bottom: 5px;" />
                <span>拍照上传</span>
              </camera-dialog>
            </el-badge>
          </div>
          <!-- 手机上传 -->
          <!-- <div v-if="type === 'card' && showPhoneCameraUpload" class="box-plus my-m-l-10 my-flex" :style="{'width':width,'height':height}">
            <el-badge value="新" :hidden="new Date()>=new Date('2024-06-30')" style="width: 100%; height: 100%">
              <phone-camera-dialog
                :limit="limit - fileList.length"
                :refId="getUrlFromData.refId"
                :refType="getUrlFromData.refType"
                @confirm="phoneCameraConfirm"
                style="width: 100%; height:100%;"
              >
                <i class="el-icon-mobile" style="font-size: 16px;margin-bottom: 5px;" />
                <span>手机上传</span>
              </phone-camera-dialog>
            </el-badge>
          </div>-->
        </li>
      </draggable>
    </ul>

    <!-- 复制图片预览dialog -->
    <preview-dialog ref="previewDialog" :url="pasteFileUrl" @close="pasteFileUrl = ''" @confirm="copyConfirm" />
    <!-- 图片裁剪dialog -->
    <cutting-dialog ref="cuttingDialog" :url="cuttingFileUrl" :fileName="cuttingFileName" @confirm="cuttingConfirm" />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import axios from 'axios'
import previewDialog from './components/previewDialog.vue'
import cuttingDialog from './components/cuttingDialog.vue'
import cameraDialog from './components/cameraDialog.vue'
// import phoneCameraDialog from './components/phoneCameraDialog'

export default {
  name: '',
  components: { draggable, previewDialog, cuttingDialog, cameraDialog },
  props: {
    limit: {
      type: [String, Number],
      default: 1
    },
    width: {
      type: String,
      default: '100px'
    },
    height: {
      type: String,
      default: '100px'
    },
    textWidth: {
      type: String,
      default: '300px'
    },
    extensions: {
      type: Array,
      default: () => {
        return ['jpg', 'jpeg', 'png', 'gif']
        //'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif', 'pdf', 'txt'
      }
    },
    type: {
      type: String,
      default: 'card' //list
    },
    arrowUpload: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    getUrlFunction: {
      type: Function
    },
    getUrlFromData: {
      type: Object,
      defaults: () => {
        return {
          refId: '',
          refType: '',
          tempRefId: ''
        }
      }
    },
    buttonText: {
      type: String,
      default: '上传文件'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    showCutting: {
      type: [String, Boolean],
      default: '' //‘’询问，true裁剪，false，原图
    },
    cuttingFixed: {
      type: [Boolean],
      default: false //是否开启截图框宽高固定比例
    },
    cuttingFixedNumber: {
      type: Array,
      default: () => {
        return [1, 1]
      } //截图框的宽高比例
    },
    showPasteUpload: {
      type: Boolean,
      default: false //是否启用剪贴上传
    },
    showCameraUpload: {
      type: Boolean,
      default: false //是否启用拍照上传
    },
    showPhoneCameraUpload: {
      type: Boolean,
      default: false //是否启用手机拍照上传
    },
    showCheck: {
      type: Boolean,
      default: false //上传前是否检查
    }
  },
  data() {
    return {
      fileList: [], //展示列表{ name: '', url: '' }
      previewFileList: [], //放大查看图片
      overIndex: '-1',
      formData: {},
      //粘贴参数
      isPasteDivFocus: false,
      pasteFileUrl: '',
      pasteFile: null,

      cuttingFileUrl: '',
      cuttingFileName: '',
      disabled: false
    }
  },
  watch: {
    getUrlFromData: {
      handler(val) {
        if (val) {
          this.formData = val
        }
      },
      immediate: true,
      deep: true
    },
    list: {
      handler(val) {
        if (val) {
          this.fileList = val
          this.fileList.forEach(item => {
            this.previewFileList.push(item.url)
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {},
  created() {
    this.$nextTick(() => {
      if (this.showPasteUpload) {
        const divElement = document.getElementById('copyDiv')
        divElement.addEventListener('focus', () => {
          this.isPasteDivFocus = true
        })

        divElement.addEventListener('blur', () => {
          this.isPasteDivFocus = false
        })
      }
    })
  },
  beforeDestroy() {
    console.log('beforeDestroy')
  },
  methods: {
    doNothing() {
      console.log('formData', this.formData)
      if (this.showCheck) {
        if (!this.formData.refId) {
          this.disabled = true
          this.$emit('check')
          console.log('点击事件已阻止')
        } else {
          this.disabled = false
        }
      }
    },
    beforeUpload() {
      console.log('beforeUpload')
    },
    //手机拍照回调
    phoneCameraConfirm(list) {
      list.forEach(item => {
        this.previewFileList.push(item.url)
        this.fileList.push(item)
      })
    },
    //拍照回调
    cameraConfirm(list) {
      console.log('list', list)
      list.forEach(item => {
        console.log(this.dataURLtoFile(item, new Date().getTime()))
        this.handleUpload(this.dataURLtoFile(item, new Date().getTime() + '.png'))
      })
    },
    // base64转文件，此处没用到
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    //获取粘贴版内容
    handlePaste(event) {
      // pasteFileUrl
      const items = (event.clipboardData || window.clipboardData).items

      let file = null

      if (!items || items.length === 0) {
        this.$message.error('当前浏览器不支持本地')
        return
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          file = items[i].getAsFile()
          break
        }
      }
      if (!file) {
        this.$message.error('粘贴内容非图片')
        return
      }
      this.pasteFile = file
      // 此时file就是我们的剪切板中的图片对象
      // 如果需要预览，可以执行下面代码
      const reader = new FileReader()
      reader.onload = event => {
        this.pasteFileUrl = event.target.result
        this.$refs.previewDialog.open()
        console.log('event.target.result', event)
      }
      reader.readAsDataURL(file)
    },
    //粘贴回调
    copyConfirm() {
      this.pasteFileUrl = ''
      this.readyUpload(this.pasteFile)
    },
    onExceed(file, fileList) {
      this.$message.error(`已达到最大数量限制：${fileList.length}个`)
    },
    //打开裁剪dialog
    openCutting(file) {
      this.cuttingFileName = file.name
      this.cuttingFileUrl = URL.createObjectURL(file) //上传成功后将图片地址赋值给裁剪框显示图片
      this.$refs.cuttingDialog.open()
      // this.cuttingFileUrl = await fileByBase64(file)// base64方式
    },
    //裁剪完成 回调
    cuttingConfirm(file) {
      this.handleUpload(file)
    },
    //查看图片
    handlePreview(index) {
      this.$refs.myImage[index].showViewer = true
    },

    //删除图片
    handleDelete(index) {
      this.$msgbox('确定移除该文件吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.fileList.splice(index, 1)
        this.previewFileList.splice(index, 1)
        this.$emit('change', this.fileList)
        // this.$message({
        //   message: '移除成功',
        //   type: 'success',
        //   duration: '2000'
        // })
      })
    },
    //el-upload 上传组件回调
    changeUpload(file) {
      this.readyUpload(file.raw)
    },
    // 准备上传  检测图片大小和类型等
    readyUpload(file) {
      console.log('changeUpload', file)
      const isMaxSize = file.size / 1024 / 1024 < 20
      let extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
      let isUpload = false
      this.extensions.forEach(item => {
        if (extension.toLowerCase() == item.toLowerCase()) {
          isUpload = true
        }
      })
      if (!isUpload) {
        this.$message.error(`暂只支持${this.extensions.toString()} 类型文件，请重新选择`)
        return false
      }
      if (!isMaxSize) {
        this.$message.error('上传文件大小不能超过 20MB')
        return false
      }

      if (extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'png') {
        //上传的是图片
        if (this.showCutting.toString().toLowerCase() == 'true') {
          //需要裁剪
          this.openCutting(file)
        } else if (this.showCutting.toString().toLowerCase() == '') {
          //需要询问是否裁剪

          this.$msgbox('是否裁剪图片？', '提示', {
            confirmButtonText: '裁剪',
            cancelButtonText: '使用原图',
            type: 'warning'
          })
            .then(() => {
              //裁剪
              this.openCutting(file)
            })
            .catch(() => {
              //原图
              this.handleUpload(file)
            })
        } else {
          //不需要裁剪
          this.handleUpload(file)
        }
      } else {
        //上传的非图片文件
        this.handleUpload(file)
      }
    },
    //上传
    handleUpload(file) {
      console.log('handleUpload', file)
      this.formData.fileName = file.name
      this.getUrlFunction(this.formData).then(res => {
        let uploadInfo = res.data
        //获取URL成功
        let sendData = new FormData() // 上传文件的data参数
        sendData.append('OSSAccessKeyId', uploadInfo.accessid)
        sendData.append('policy', uploadInfo.policy)
        sendData.append('Signature', uploadInfo.signature)
        sendData.append('key', uploadInfo.key) //'file_' + new Date().getTime() + '_' + option.file.name) //上传的文件路径
        sendData.append('success_action_status', 200) // 指定返回的状态码
        sendData.append('callback', uploadInfo.callback) // 指定返回的状态码
        sendData.append('type', 'image/jpeg')
        sendData.append('file', file)
        //提交
        axios({
          method: 'post',
          url: uploadInfo.host,
          data: sendData
        })
          .then(res => {
            //这里使用了ES6的语法
            res = res.data
            this.previewFileList.push(res.data.url)
            this.fileList.push(res.data)
            this.$emit('change', this.fileList)
            this.$message({
              message: res.message,
              type: 'success',
              duration: '2000'
            })
          })
          .catch(error => {
            this.$message({
              message: '上传失败：' + error,
              type: 'error',
              duration: '2000'
            })
          })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
  margin-bottom: 0 !important;
}
.box-plus {
  border: 1px dashed #c0ccda;
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  color: #666;

  &:hover {
    background-color: #f5f5f5;
  }
}
.box-card {
  display: flex;
  flex-wrap: wrap;
  .box-card-li {
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      color: #fff;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0.5);
      // transition: opacity 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.box-list {
  width: min-content;
  .box-list-li {
    font-size: 14px;
    color: #606266;
    margin-bottom: 8px;
    border-radius: 4px;
    position: relative;
    padding: 8px;
    line-height: 14px !important;
    a {
      display: table;
      white-space: nowrap;
      padding-right: 30px;
    }
    .close {
      height: 100%;
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 0;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }
  .box-list-li:hover {
    background: rgba(64, 158, 225, 0.1);
    color: #1890ff;
  }
}
</style>