<template>
  <div>
    <div class="add-form" style=" max-width: 500px; margin: 0 auto;">
      <el-form ref="saveForm" :rules="rules" :model="form" v-loading="loading" label-width="100px" style="padding-bottom: 50px;">
        <!-- 基本信息 -->
        <div class="wrap-group" style="padding: 30px 30px 10px 0;">
          <div style=" text-align: center; font-size: 18px; font-weight: bold;">免费开通在线支付</div>
          <div style="margin-top: 10px; margin-bottom: 30px; text-align: center;font-size: 12px; color:#666;">支持微信、支付宝、信用卡，多种方式高效收款</div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="门店名称" prop="name">
                <el-input v-model="form.name" placeholder="门店名称" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="门店地址" prop="address">
                <el-input v-model="form.address" placeholder="门店地址" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系人" prop="contact">
                <el-input v-model="form.contact" placeholder="联系人" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="联系电话" prop="tel">
                <el-input v-model="form.tel" placeholder="联系电话" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div style="margin-top:10px; text-align: center;font-size: 14px; color:#999;">
          <contact-service text="联系客服"></contact-service>
        </div>
        <!-- 照片 -->
        <div class="wrap-group" style="margin-top: 10px; padding-right: 30px;">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="营业执照">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.businessLicensePath ? [{url:form.businessLicensePath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantBusinessLicense'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="银行卡">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.bankCardPath ? [{url:form.bankCardPath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantBankCard'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="门头照">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.storeleadPath ? [{url:form.storeleadPath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantStoreHead'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="内景照">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.storeInsidelPath ? [{url:form.storeInsidelPath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantStoreInside1'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="身份证正面">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.identityCardAPath ? [{url:form.identityCardAPath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantIdentityCardA'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="身份证反面">
                <upload
                  width="80px"
                  height="80px"
                  :list="form.identityCardBPath ? [{url:form.identityCardBPath}] : []"
                  :limit="1"
                  showCutting="false"
                  showCheck
                  :get-url-function="getUrlFunction"
                  :get-url-from-data="{refId:form.id,refType:'PayMerchantIdentityCardB'}"
                  @change="attaChange"
                  @check="check"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="add-footer">
      <div
        style=" width: 500px; height: 100%; background: #fff; display: flex; align-items: center; justify-content: center; box-shadow: 2px 2px 18px #eee;"
      >
        <el-button type="primary" @click="save(true)" style="width: 90%; height: 40px;">确定并提交申请</el-button>
      </div>
    </div>
  </div>
</template> 

<script>
import API from '@/api/onlinePay.js'

import API_UPLOAD from '@/api/upload.js'
import Upload from '@/components/Upload'
export default {
  name: 'MobilePay',
  components: {
    Upload
  },
  data() {
    return {
      loading: false,
      clientId: '',
      form: {
        id: '',

        name: '',
        address: '',
        contact: '',
        tel: '',
        identityCardAPath: '',
        identityCardBPath: '',
        businessLicensePath: '',
        bankCardPath: '',
        storeleadPath: '',
        storeInsidelPath: ''
      },
      rules: {
        name: [{ required: true, message: '请填写门店名称', trigger: 'blur' }],
        address: [{ required: true, message: '请填写门店地址', trigger: 'blur' }],
        contact: [{ required: true, message: '请填写联系人', trigger: 'blur' }],
        tel: [{ required: true, message: '请填写联系电话', trigger: 'blur' }]
      },
      getUrlFunction: API_UPLOAD.getUrl
    }
  },
  watch: {},
  computed: {},
  created() {
    console.log('this.$route.query', this.$route.query)
    this.clientId = this.$route.query.clientId || 'ae45b63b0f'
    this.form.name = this.$route.query.name || ''
    this.form.address = this.$route.query.address || ''
    this.form.contact = this.$route.query.contact || ''
    this.form.tel = this.$route.query.tel || ''

    this.getInfo()
  },
  methods: {
    getInfo() {
      this.loading = true
      API.getInfo({ clientId: this.clientId }).then(res => {
        console.log('res', res)
        if (res.data.length >= 1) {
          this.form = res.data[0]
        }
        this.loading = false
      })
    },
    save(isSubmit) {
      let obj = JSON.parse(JSON.stringify(this.form))
      obj.isSubmit = isSubmit || false
      obj.clientId = this.clientId
      API.save(obj).then(res => {
        console.log('res', res)
        this.form.id = res.data
        if (!isSubmit) {
          this.$message({
            message: `信息保存成功，请您再次点击上传图片`,
            type: 'success',
            duration: '2000'
          })
        } else {
          this.$message({
            message: `保存成功`,
            type: 'success',
            duration: '2000'
          })
        }
      })
    },
    attaChange() {
      //   if (this.form.id > 0) {
      //     API.saveAttachments({ id: this.form.id, attachments: list }).then(res => {})
      //   }
      //   this.fileList = list
    },
    check() {
      if (this.form.name && this.form.address && this.form.contact && this.form.tel) {
        this.save()
      } else {
        this.$message.error('请先填写门店名称、门店地址、联系人、联系电话')
      }
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.add-footer {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss" >
.add-form {
  .el-form-item {
    .el-form-item__error {
      padding-top: 0px !important;
    }
  }
}
</style>