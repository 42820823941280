import request from '@/common/request/request'


function getUrl(params) { return request({ url: '/Upload/GetUrl', method: 'get', params }) }
function upload(url, data) { return request({ url: url, method: 'post', data }) }

export default {
    getUrl,
    upload,
}
