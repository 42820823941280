<template>
  <span>
    <span
      @click.stop="open"
      style="width: 100%; height:100%;display: flex; flex-direction: column; align-items: center; justify-content: center;"
    >
      <slot></slot>
    </span>
    <el-dialog append-to-body :title="title" :width="width" top="10vh" :visible="dialogVisible" @close="close">
      <div class="dialog-wrap" v-if="dialogVisible" v-loading="loading">
        <canvas style="display:none;" id="canvasCamera" :width="cameraSize.width" :height="cameraSize.height"></canvas>
        <div class="my-flex my-flex-direction" style="width:100%;justify-content: center;  flex-direction: column; ">
          <!-- 摄像头组件 video -->
          <video id="videoCamera" :width="cameraSize.width" :height="cameraSize.height" autoplay style="background: #000;"></video>
          <div class="color-info my-font-xs my-p-t-5">{{cameraSize.width}}x{{ cameraSize.height }}</div>
          <p class="color-error my-p-t-10" v-if="cameraError">摄像头初始化失败，请检查摄像头</p>
        </div>
        <div style="width: 100%; height: 120px; margin-top: 20px;  overflow-x: scroll;white-space: nowrap; ">
          <!-- 已拍照的图片列表 -->
          <div
            style="cursor: pointer;position: relative; display: inline-block;"
            v-for="(item,index) in imageList"
            :key="index"
            class="my-m-l-10"
            :style="{'width':imageWidth,'height':imageHeight}"
            @mouseover="overIndex = index"
            @mouseleave="overIndex = '-1'"
          >
            <el-image fit="cover" style="width: 100%; height:100%" ref="myImage" :src="item" :preview-src-list="imageList"></el-image>
            <span
              v-if="overIndex == index "
              style=" position: absolute;width: 100%;height: 100%;left: 0;top: 0;color: #fff;font-size: 16px;background-color: rgba(0, 0, 0, 0.5);
                        display: flex;align-items: center;justify-content: center;"
            >
              <i class="el-icon-view" style="margin-right: 5px;" @click="handlePreview(index)"></i>
              <i class="el-icon-delete" v-if="showDelete" @click="handleDelete(index)"></i>
            </span>
          </div>
        </div>
        <audio ref="audioPz" src="@/assets/mp3/pz.mp3" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close" style="margin-right:10px;">取消</el-button>
        <el-radio-group v-model="scale" style="margin-right:10px;" @change="changeScale">
          <el-radio-button label="4:3">4:3</el-radio-button>
          <el-radio-button label="16:9">16:9</el-radio-button>
          <el-radio-button label="1:1">1:1</el-radio-button>
        </el-radio-group>
        <el-button type="primary" icon="el-icon-camera" @click="setImage">拍照</el-button>
        <el-button type="primary" @click="confirm">保存</el-button>
      </div>
    </el-dialog>
  </span>
</template> 
        
 <script>
// import elDragDialog from '@/directive/el-drag-dialog'

export default {
  name: '',
  directives: {},
  components: {},
  props: {
    title: {
      type: String,
      default: '拍照'
    },
    width: {
      type: String,
      default: '900px'
    },
    videoHeight: {
      type: [String, Number],
      default: 375
    },
    imageWidth: {
      type: String,
      default: '100px'
    },
    imageHeight: {
      type: String,
      default: '100px'
    },
    limit: {
      type: [String, Number],
      default: 1
    },
    showDelete: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      overIndex: '-1',
      imageList: [],
      thisCanvas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      scale: '4:3',
      cameraError: false
    }
  },
  computed: {
    cameraSize() {
      let height = this.videoHeight
      let width = height
      if (this.scale == '4:3') {
        width = Number(((height / 3) * 4).toFixed(0))
      } else if (this.scale == '16:9') {
        width = Number(((height / 9) * 16).toFixed(0))
      } else if (this.scale == '1:1') {
        width = Number(((height / 1) * 1).toFixed(0))
      } else {
        width = Number(((height / 1) * 1).toFixed(0))
      }
      return {
        width: width,
        height: height
      }
    }
  },
  created() {},

  methods: {
    open() {
      this.dialogVisible = true
      this.scale = localStorage.getItem('cameraScale') || '4:3'
      this.$nextTick(() => {
        this.getCompetence()
      })
    },
    close() {
      this.dialogVisible = false
      this.$emit('close')
      this.stopNavigator()
      this.imageList = []
    },
    //确认保存
    confirm() {
      if (this.imageList.length <= 0) {
        this.$message({
          message: `照片不能为空`,
          type: 'none',
          duration: '2000'
        })
        return false
      }
      this.dialogVisible = false
      this.$emit('confirm', this.imageList)
    },
    //变更比例
    changeScale() {
      this.getCompetence()
      localStorage.setItem('cameraScale', this.scale)
    },
    //查看图片
    handlePreview(index) {
      this.$refs.myImage[index].showViewer = true
    },

    //删除图片
    handleDelete(index) {
      this.imageList.splice(index, 1)
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      this.loading = true
      var _this = this
      _this.thisCanvas = document.getElementById('canvasCamera')
      _this.thisContext = this.thisCanvas.getContext('2d')
      _this.thisVideo = document.getElementById('videoCamera')
      _this.thisVideo.style.display = 'block'
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }
      var constraints = {
        audio: false,
        video: {
          width: this.cameraSize.width,
          height: this.cameraSize.height,
          transform: 'scaleX(-1)'
        }
      }
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ('srcObject' in _this.thisVideo) {
            _this.thisVideo.srcObject = stream
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            _this.thisVideo.src = window.URL.createObjectURL(stream)
          }
          _this.thisVideo.onloadedmetadata = function () {
            _this.thisVideo.play()
          }
          _this.cameraError = false
          _this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.$message({
            message: err,
            type: 'error',
            duration: '2000'
          })
          _this.cameraError = true
          _this.loading = false
        })
    },
    //  绘制图片（拍照功能）
    setImage() {
      if (this.cameraError) {
        this.$message({
          message: `请检查摄像头`,
          type: 'none',
          duration: '2000'
        })
        return false
      }
      if (this.limit - this.imageList.length <= 0) {
        this.$message({
          message: `最多可选${this.limit}张照片`,
          type: 'none',
          duration: '2000'
        })
        return false
      }
      this.$refs.audioPz.play()
      // canvas画图
      this.thisContext.drawImage(this.thisVideo, 0, 0, this.cameraSize.width, this.cameraSize.height)
      // 获取图片base64链接
      var image = this.thisCanvas.toDataURL('image/png')
      this.imageList.push(image)
    },
    // 关闭摄像头
    stopNavigator() {
      this.cameraError = false
      this.thisVideo.srcObject.getTracks()[0].stop()
    }
  }
}
</script>
        
<style lang="scss" scoped>
.setting {
  position: absolute;
  right: 10px;
  top: 10px;

  .item {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 2px solid rgba(255, 255, 255, 0.9);
    background-color: rgba(000, 000, 000, 0.2);
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}
</style>
        