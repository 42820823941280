<template>
  <span>
    <span @click.stop="handleOpen">
      <slot></slot>
    </span>
    <el-dialog append-to-body :title="title" top="15vh" :visible="dialogVisible" @close="close">
      <div class="dialog-wrap crop-dialog">
        <div class="cropper-content">
          <div class="cropper" style="text-align:center">
            <vue-cropper
              ref="cropper"
              :img="option.img"
              :outputSize="option.size"
              :outputType="option.outputType"
              :info="true"
              :full="option.full"
              :canMove="option.canMove"
              :canMoveBox="option.canMoveBox"
              :original="option.original"
              :autoCrop="option.autoCrop"
              :fixed="option.fixed"
              :fixedNumber="option.fixedNumber"
              :centerBox="option.centerBox"
              :infoTrue="option.infoTrue"
              :fixedBox="option.fixedBox"
              :autoCropWidth="option.autoCropWidth"
              :autoCropHeight="option.autoCropHeight"
              @cropMoving="cropMoving"
            />
          </div>
        </div>
        <div class="action-box">
          <!-- <el-upload class="upload-demo" action :auto-upload="false" :show-file-list="false" :on-change="changeUpload">
            <el-button type="primary" plain>更换图片</el-button>
          </el-upload>-->
          <!-- <el-button type="primary" plain @click="clearImgHandle">清除图片</el-button> -->
          <el-button type="primary" plain @click="rotateLeftHandle">左旋转</el-button>
          <el-button type="primary" plain @click="rotateRightHandle">右旋转</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(1)">放大</el-button>
          <el-button type="primary" plain @click="changeScaleHandle(-1)">缩小</el-button>
          <el-button type="primary" plain @click="downloadHandle('blob')">下载</el-button>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="confirm" :loading="loading">上传</el-button>
      </div>
    </el-dialog>

    <!-- vueCropper 剪裁图片实现-->
    <!-- <el-dialog title="图片剪裁" :visible.sync="dialogVisible" class="crop-dialog" append-to-body>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="cuttingFinish" :loading="loading">确认</el-button>
      </div>
    </el-dialog>-->
  </span>
</template> 
        
     <script>
// import elDragDialog from '@/directive/el-drag-dialog'
import { VueCropper } from 'vue-cropper'

export default {
  name: '',
  directives: {},
  components: { VueCropper },
  props: {
    title: {
      type: String,
      default: '图片剪裁'
    },
    url: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      // 裁剪组件的基础配置option
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        canMoveBox: true, // 截图框能否拖动
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: this.cuttingFixed, // 是否开启截图框宽高固定比例
        fixedNumber: this.cuttingFixedNumber, // 截图框的宽高比例[1, 1]
        full: false, // 是否输出原图比例的截图
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      // 防止重复提交
      loading: false
    }
  },
  computed: {},
  created() {},

  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('close')
    },
    confirm() {
      // 获取截图的 blob 数据
      this.$refs.cropper.getCropBlob(blob => {
        this.loading = true
        let file = new File([blob], this.fileName, { type: 'image/jpeg', lastModified: Date.now() })
        this.$emit('confirm', file)
        this.dialogVisible = false
        // let url = URL.createObjectURL(blob)
        // this.handleUpload(file, url)
      })

      // 获取截图的 base64 数据
      // this.$refs.cropper.getCropData(data => {
      //     console.log(data)
      // })
    },
    open() {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.loading = false
        this.option.img = this.url
      })
    },
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    // 左旋转
    rotateLeftHandle() {
      this.$refs.cropper.rotateLeft()
    },
    // 右旋转
    rotateRightHandle() {
      this.$refs.cropper.rotateRight()
    },
    // 下载
    downloadHandle(type) {
      let aLink = document.createElement('a')
      aLink.download = 'author-img'
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob(data => {
          aLink.href = URL.createObjectURL(data)
          aLink.click()
        })
      } else {
        this.$refs.cropper.getCropData(data => {
          aLink.href = data
          aLink.click()
        })
      }
    },
    // 清理图片
    clearImgHandle() {
      this.option.img = ''
    },
    // 截图框移动回调函数
    cropMoving(data) {
      console.log(data)
      // 截图框的左上角 x，y和右下角坐标x，y
      // let cropAxis = [data.axis.x1, data.axis.y1, data.axis.x2, data.axis.y2]
      // console.log(cropAxis)
    }
  }
}
</script>
        
<style lang="scss" scoped>
.crop-dialog {
  .cropper-content {
    padding: 0 40px;

    .cropper {
      width: auto;
      height: 350px;
    }
  }

  .action-box {
    padding: 25px 40px 0 40px;
    display: flex;
    justify-content: center;

    button {
      width: 80px;
      margin-right: 15px;
    }
  }

  .dialog-footer {
    button {
      width: 100px;
    }
  }
}
</style>
        