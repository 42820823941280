import request from '@/common/request/request'

function getInfo(data) { return request({ url: '/OnlinePay/GetInfo', method: 'post', data }) }

function save(data) { return request({ url: '/OnlinePay/Save', method: 'post', data }) }

export default {
    getInfo,
    save
}
